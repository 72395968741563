import "./set-public-path.js";
import React from "react";
import ReactDOMClient from "react-dom/client";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { property } from "lodash";

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  featureToggles: [],
  setPublicPath(publicPath: string) {
    __webpack_public_path__ = publicPath;
  },
  childAppName: "engagements-ui",
});

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  loadRootComponent: () =>
    import(
      /* webpackChunkName: "engagements-ui-root" */ "./root.component"
    ).then(property("default")),
  domElementGetter,
});

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("engagements-ui");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "engagements-ui");
    document.body.appendChild(el);
  }

  return el;
}

export function getCreateEngagementOverlay() {
  return import(
    /* webpackChunkName: "create-engagement-overlay" */ "./engagement/engagement-create.component"
  );
}

export const getClientEngagementComponent = () =>
  import(
    /* webpackChunkName: "client-engagement" */ "./client-engagement/client-engagement.component"
  );
